// @flow

import React from 'react';
import { withTranslatedRouter } from 'shared_data/providers/url/withTranslatedRouter';
import {
  HTML_ID_APPLICATION_MSG_TOP_LIST,
  ScrollToTopOnPathUpdate,
  PreloaderPage,
} from '@riseart/common';
import { HeaderContainer } from '@riseart/layout';
import { Query } from 'shared_services/apollo/Query';
import { LocationManager } from 'shared_services/riseart/url/Location';
import RENDER_CMS_VIEW_QUERY from 'shared_data/queries/cms/renderView.graphql';
import { Header } from 'shared_components/layouts/header/Header';
import { Footer } from 'shared_components/layouts/Footer';
import { HOCPagePreloader } from 'shared_hocs/preloaders/Page';
import { RouteConfigContext } from 'shared_data/providers/routeconfig/RouteConfigContext';
import { MetaProvider } from 'shared_data/providers/meta/Meta';
import { CmsViewsProvider } from 'shared_hocs/cms/views/Provider';
import { errors as ERRORS_ENUM, meta as META_ENUM } from 'Enum';
import { WithOffsetHeight } from 'shared_hocs/gui/withOffsetHeight';
import { CartSidebar } from 'shared_components/cart/Sidebar';
import { RegionalFormOverlay } from 'shared_components/forms/regional/Overlay';
import { RecentArtworksWidget } from 'shared_components/arts/widgets/RecentArtworks';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import {
  HOCMessagesNotificationsManager,
  NotificationMessages,
} from 'shared_hocs/messages/notifications/Manager';
import {
  HOCMessagesApplicationManager,
  ApplicationMessages,
} from 'shared_hocs/messages/application/Manager';

import { layoutContentCls } from 'shared_components/layouts/Default.less';
import { appMessagesBottomCls } from 'shared_components/messages/styles.less';

export const COLOR_THEMES = {
  WHITE: 'white',
  TRANSPARENT: 'transparent',
};

const DEFAULT_LAYOUT_QUERY_CONTEXT = {
  customOptions: {
    errorSuppressFromResponse: true,
    // this custom errorFilter will iterate over the graphql errors (if any)
    // and will skip ResourceNotFoundException only for this query,
    // because on some pages we don't have CMS data to load and then it returns this ResourceNotFoundException
    errorFilter: (errorList = []) =>
      errorList.filter((error) => error && error.errorType !== 'ResourceNotFoundException'),
    error: {
      handler: ERRORS_ENUM.handlers.SILENT,
    },
  },
};

const PagePreloader = HOCPagePreloader(PreloaderPage);

type Props = {
  className?: string,
  hasNotification?: boolean,
  hasApplicationMessages?: boolean,
  hasHeader?: boolean,
  hasFooter?: boolean,
  hasPagePreloader?: boolean,
  showRecentArtworkWidget?: boolean,
  subscribe?: Array<Object>,
  notify?: Function,
  headerComponent?: ?React$Element<any>,
  layoutQueryErrorHandler?: Object,
  theme?: string,
  scrollTopOnPathChange?: boolean,
  children: any,
  transparent?: boolean,
};

// HOCs
const HOCScrollToTop = withTranslatedRouter(ScrollToTopOnPathUpdate);
const NotificationMessagesContainer = HOCMessagesNotificationsManager(NotificationMessages);
const ApplicationMessagesContainer = HOCMessagesApplicationManager(ApplicationMessages);

export const LayoutDefault = ({
  className,
  hasNotification = true,
  hasApplicationMessages = true,
  hasPagePreloader = true,
  showRecentArtworkWidget = true,
  hasHeader = true,
  hasFooter = true,
  headerComponent: CustomHeader,
  layoutQueryErrorHandler = {},
  scrollTopOnPathChange = false,
  children,
  transparent = false,
}: Props) => {
  const store = useStoreCode();
  const queryContext = { ...DEFAULT_LAYOUT_QUERY_CONTEXT, ...layoutQueryErrorHandler };
  const wrapperProps = className ? { className } : null;

  return (
    <div {...wrapperProps}>
      {scrollTopOnPathChange && <HOCScrollToTop />}
      {/*
        LD+JSON data is not using any meta subscriber,
        so we are using another MetaProvided specificly for it
        and we do not include it in the PAGE_META subscriber used above
      */}
      <MetaProvider
        meta={{
          [META_ENUM.METATYPE.SCRIPT_LD_JSON_WEBPAGE]: {
            url: LocationManager.get('fullPath'),
          },
        }}
      />
      <RouteConfigContext.Consumer>
        {({ module, cmsViewRenderSsr }) => (
          <Query
            ssr={!!cmsViewRenderSsr}
            query={RENDER_CMS_VIEW_QUERY}
            variables={{ name: module, store }}
            errorPolicy="all"
            context={queryContext}
            skip={!module || !store}
          >
            {({ loading, data = {} }: Object) => (
              <CmsViewsProvider loading={loading} data={data && data.renderCmsView} />
            )}
          </Query>
        )}
      </RouteConfigContext.Consumer>
      {hasPagePreloader && <PagePreloader />}
      {hasNotification ? <NotificationMessagesContainer /> : null}
      <WithOffsetHeight>
        {({ notificationsHeight }) => (
          <HeaderContainer notificationHeight={notificationsHeight}>
            {hasApplicationMessages ? (
              <ApplicationMessagesContainer
                htmlId={HTML_ID_APPLICATION_MSG_TOP_LIST}
                messageLocation="top"
              />
            ) : null}
            {hasHeader
              ? CustomHeader || (
                  <RouteConfigContext.Consumer>
                    {({ ui }) => (
                      <Header
                        transparent={transparent}
                        showRecentWidgetOnPage={showRecentArtworkWidget}
                        disableSticky={!!(ui && ui.disableStickyHeader)}
                      />
                    )}
                  </RouteConfigContext.Consumer>
                )
              : null}
          </HeaderContainer>
        )}
      </WithOffsetHeight>
      <div className={layoutContentCls}>{children}</div>
      {hasFooter ? <Footer /> : null}
      <CartSidebar />
      <RegionalFormOverlay />
      <RecentArtworksWidget showOnPage={showRecentArtworkWidget} />
      {hasApplicationMessages ? (
        <div className={appMessagesBottomCls}>
          <ApplicationMessagesContainer messageLocation="bottom" />
        </div>
      ) : null}
    </div>
  );
};
