// @flow

import queryString from 'query-string';
import React from 'react';
import type { Node } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useUrlParams } from 'shared_data/providers/url/useUrlParams';
import { search as ENUM_SEARCH } from 'Enum';
import { gtmMainSearchText } from 'shared_data/providers/google/tagmanager/Actions';
import { UrlAssembler } from 'shared_services/riseart/utils/UrlAssembler';
import { SearchComponent } from 'shared_components/forms/search/Component';

/**
 * SearchForm
 *
 * @param {Object} props
 * @returns {Node}
 */
export const SearchForm = ({
  onSubmit,
  onClear,
  enableClear = false,
  preventDefault = false,
  excludedParameters = [],
  domain,
  useQueryString = true,
  allowTracking = false,
  ...props
}: Object): Node => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { translatedLocation: location } = useUrlParams();
  const { pathname, search, hash } = location;
  const { q, ...restParams } = useQueryString ? queryString.parse(search) : {};

  // exclude any query parameters in clear and submit url
  const restQueryParams =
    excludedParameters && excludedParameters.length > 0
      ? Object.keys(restParams).reduce((accumulator, qsKey) => {
          if (excludedParameters.indexOf(qsKey) > -1) {
            return accumulator;
          }

          return { ...accumulator, [qsKey]: restParams[qsKey] };
        }, {})
      : restParams;

  /**
   * handleSubmit
   *
   * @param {Object} values
   */
  function handleSubmit(values: Object) {
    if (typeof onSubmit === 'function') {
      onSubmit(values);
    }

    if (preventDefault) {
      return;
    }

    const searchDomain = values.domain || domain;
    const searchValue = (values && values.q) || '';
    const qTextSearch = { q: searchValue };

    // Track any change it tracking is allowed for the search component
    if (allowTracking) {
      dispatch(gtmMainSearchText({ query: searchValue, domain: searchDomain }));
    }

    switch (searchDomain) {
      case ENUM_SEARCH.domain.ALL:
        history.push(UrlAssembler.search({ search: qTextSearch }));
        break;
      case ENUM_SEARCH.domain.ART:
        history.push(UrlAssembler.artList({ search: qTextSearch }));
        break;
      case ENUM_SEARCH.domain.ARTIST:
        history.push(UrlAssembler.artistList({ search: qTextSearch }));
        break;
      default: {
        const urlOptions = UrlAssembler.mergeOptions({
          search: {
            ...restQueryParams,
            ...qTextSearch,
          },
        });
        history.push(`${pathname}${urlOptions.search}${hash}`);
      }
    }
  }

  /**
   * handleClear
   *
   * @param {Object} values
   */
  function handleClear(values: Object) {
    if (typeof onClear === 'function') {
      onClear(values, history);
    } else {
      const urlOptions = UrlAssembler.mergeOptions({
        search: { ...restQueryParams, q: '' },
      });
      history.push(`${pathname}${urlOptions.search}${hash}`);
    }
  }

  return (
    <SearchComponent
      {...props}
      domain={domain}
      onSubmit={handleSubmit}
      parentOnSubmit={onSubmit}
      {...(enableClear || onClear ? { onClear: handleClear } : null)}
      initialValue={props.initialValue || q}
    />
  );
};
